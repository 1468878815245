import React from 'react';
import {Row, Col} from 'react-bootstrap';
import {connect} from 'react-redux';
import {formatPersonEmailRFC, isAdmin} from "../../utils/methods";
import {
    changePresentationCategory,
    markAsRead,
    markSelection,
    removeSelection,
    postComment,
    changeFlag,
    castPresentationScore,
    getPresentationAvgScore,
    clearPresentationRating,
    changeDuration
} from '../../actions/presentation-actions';
import PresentationMeta from './PresentationMeta';
import PresentationTags from './PresentationTags';
import PresentationActivity from './PresentationActivity';
import PresentationSpeakers from './PresentationSpeakers';
import SelectionButtons from './SelectionButtons';
import Ribbon from './Ribbon';
import Wave from '../Wave';
import Guidelines from './Guidelines';
import MediaUploads from './PresentationMaterials';
import ExtraQuestions from './ExtraQuestions';
import T from 'i18n-react/dist/i18n-react'
import styles from './index.module.scss';
import Copy2ClipBoardButton from "../Copy2ClipBoardButton";

/**
 * class PresentationDetail
 */
class PresentationDetail extends React.Component {

    constructor(props) {
        super(props);
        this.toggleMySelection = this.toggleMySelection.bind(this);
        this.toggleGroupSelection = this.toggleGroupSelection.bind(this);
        this.onChangePresentationRating = this.onChangePresentationRating.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {presentation, trackChairUser, track, selectionOpen} = this.props;
        const canMark = selectionOpen && trackChairUser && trackChairUser.isChairForTrack(track.id);

        if(presentation && presentation.id && !presentation.viewed && canMark && presentation.track_id === track.id) {
            this.props.markAsRead(presentation.id);
        }
    }

    toggleMySelection() {
        this.props.toggleForMe(
            this.props.presentation.id,
            !this.props.presentation.selected
        );
    }

    toggleGroupSelection() {
        this.props.toggleForGroup(
            this.props.presentation.id,
            !this.props.presentation.group_selected
        );
    }

    onChangePresentationRating(presentation, scoreType) {
        return this.props.castPresentationScore(presentation.id, scoreType.id)
          .then(() => this.props.getPresentationAvgScore(presentation.id))
    };

    render () {
        const {
            summit,
            selectionPlan,
            actionTypes,
            allTracks,
            selectionOpen,
            loggedUser,
            index,
            total,
            presentation,
            track,
            trackChairUser,
            loadingPresentation
        } = this.props;

        if (!track || loadingPresentation) {
            return <Wave />
        } else if (!presentation) {
            return (
                <div className={styles.selectPresentation}>
                    Please select a presentation from the list.
                </div>
            );
        }

        const presentationHasUpdates = false;
        const memberAdmin = isAdmin(loggedUser);
        const isChair = (trackChairUser && trackChairUser.isChairForTrack(track.id));
        const canEdit = selectionOpen && isChair;
        const speakers = presentation.speakers;
        const moderators = presentation?.moderator ? [presentation?.moderator] : [];
        const someAllowDropBox = presentation.media_uploads.some(mu => (mu.media_upload_type.private_storage_type === 'DropBox'));

        // get presentation extra questions
        const extraQuestions = presentation.hasOwnProperty("extra_questions") ?
            presentation.extra_questions : [];

        const allEmails = speakers.filter(s => s.email).map(s => formatPersonEmailRFC(s)).join(',');
        return (
            <div className={styles.wrapper}>
                <Ribbon status={presentation.selection_status} />
                <Guidelines canEdit={canEdit} index={index} total={total} />
                <div className={styles.detailsWrapper}>
                    <div className={styles.detailsHeader}>
                        {presentationHasUpdates && canEdit &&
                        <div className="alert alert-info">
                            {T.translate("presentation_details.new_comments_alert")}
                        </div>
                        }
                        {canEdit &&
                        <div className={styles.selectionsRemaining}>
                            Selections remaining: {presentation.remaining_selections}
                        </div>
                        }
                        <Row>
                            <Col md={6} xs={12} sm={12}>
                                <h2>{`${presentation.id} - ${presentation.title}`}</h2>
                            </Col>
                            <Col className="buttons-container" md={6} xs={12} sm={12}>
                                {canEdit &&
                                <SelectionButtons
                                    presentation={presentation}
                                    track={track}
                                    loggedUser={loggedUser}
                                    onSelect={this.props.markSelection}
                                    onClear={this.props.removeSelection}
                                />
                                }
                            </Col>
                        </Row>
                    </div>
                    <PresentationTags tags={presentation.tags} onTagClick={this.props.onTagClick} />
                    <PresentationMeta
                        summit={summit}
                        selectionPlan={selectionPlan}
                        actionTypes={actionTypes}
                        allTracks={allTracks}
                        track={track}
                        presentation={presentation}
                        canEdit={canEdit}
                        memberAdmin={memberAdmin}
                        onCategoryChange={this.props.changePresentationCategory}
                        onChangeRating={this.onChangePresentationRating}
                        onClearRating={this.props.clearPresentationRating}
                        onPostNote={this.props.postComment}
                        onFlagChange={this.props.changeFlag}
                        onDurationChange={this.props.changeDuration}
                    />
                    <div className={styles.speakersSection}>
                        <h3>{T.translate("presentation_details.speakers")}</h3>
                        <PresentationSpeakers
                            speakers={speakers}
                            presentationId={presentation.id}
                            canEmail={canEdit}
                            onPostNote={this.props.postComment}
                        />
                    </div>
                    {moderators.length > 0 &&
                        <div className={styles.speakersSection}>
                            <h3>{T.translate("presentation_details.moderators")} </h3>
                            <PresentationSpeakers
                                speakers={moderators}
                                presentationId={presentation.id}
                                canEmail={canEdit}
                                onPostNote={this.props.postComment}
                            />
                        </div>
                    }
                    {extraQuestions.length > 0 &&
                        <div className={styles.extraQuestionsSection}>
                            <h3>{T.translate("presentation_details.extra_questions")}</h3>
                            <ExtraQuestions extraQuestions={extraQuestions} />
                        </div>
                    }
                    {(moderators.length > 0 || speakers.length > 0) &&
                        <div className={styles.emailSection}>

                            <span title={allEmails}>
                                <i className="fa fa-envelope"/>&nbsp;All speaker emails
                            </span>&nbsp;<Copy2ClipBoardButton text2Copy={allEmails}
                                                               justCopy={!canEdit}
                                                               onPostNote={this.props.postComment}
                                                               presentationId={presentation.id}
                        />
                        </div>
                    }
                    {presentation.media_uploads.length > 0 &&
                        <div className={styles.materialsSection}>
                            <h3>{T.translate("presentation_details.materials")}</h3>
                            {someAllowDropBox &&
                                <p>{T.translate("presentation_details.materials_info")}</p>
                            }
                            <MediaUploads mediaUploads={presentation.media_uploads} />
                        </div>
                    }
                    {(isChair || memberAdmin) &&
                        <PresentationActivity
                            presentation={presentation}
                            onComment={this.props.postComment}
                            readOnly={!canEdit}
                            someAllowDropBox={someAllowDropBox}
                        />
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ baseState, loggedUserState, presentationsState }) => ({
    loggedUser: loggedUserState.member,
    ...baseState,
    ...presentationsState
});

export default connect(
    mapStateToProps,
    {
        markAsRead,
        markSelection,
        removeSelection,
        postComment,
        changePresentationCategory,
        changeFlag,
        castPresentationScore,
        getPresentationAvgScore,
        clearPresentationRating,
        changeDuration
    }
)(PresentationDetail);
