/**
 * Copyright 2018 OpenStack Foundation
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 **/
 import React from 'react';
 import { connect } from 'react-redux'
 import {Redirect} from "react-router";
 import { getAvailableSummits, clearCurrentSummit } from '../actions/base-actions';
 import {SummitDropdown} from 'openstack-uicore-foundation/lib/components';
 import {isAdmin, isSuperAdmin} from "../utils/methods";
 import styles from '../styles/landing-page.module.scss';

 class LandingPage extends React.Component {

     componentDidMount() {
         this.props.clearCurrentSummit();
         this.props.getAvailableSummits();
     }
 
     onSummitSelect = (summitId) => {
         const {summits, history} = this.props;
         const summit = summits.find(s => s.id === summitId);
         history.push(`/app/${summit.slug}`);
     };
     
     getSummitOptions = () => {
         const {summits, member} = this.props;
         const chairForSummits = member.track_chairs.map(t => t.summit_id);
 
         // depends on user clocks
        const secondsSinceEpoch = Math.round(Date.now() / 1000);

        if (isAdmin(member)) {
            if (isSuperAdmin(member)) return summits;

            const summitsWithAccess = member.summit_permission_groups?.reduce((result,item)=>{
                const unique = item.summits.filter(s => !result.includes(s));
                return [...result, ...unique];
            },[]) || [];

            return summits.filter(s => summitsWithAccess.includes(s.id));
        }

        // For non admin user filter out the summits which all selection plans have ended selection process
        return summits.filter(s => chairForSummits.includes(s.id) && s.selection_plans?.find(sp => sp.selection_end_date > secondsSinceEpoch));
     };

     render(){
         const summitOptions = this.getSummitOptions();
 
        if (summitOptions.length === 1) {
            return <Redirect to={`/app/${summitOptions[0].slug}`} />
        }
 
         return (
             <div className={`container ${styles.wrapper}`}>
                 <h1>Please select a show to start: </h1>
                 <div className={styles.selectWrapper}>
                     <SummitDropdown
                         summits={summitOptions}
                         onClick={this.onSummitSelect}
                         actionLabel="Enter"
                         actionClass={styles.enterButton}
                         big
                     />
                 </div>
             </div>
         );
     }
 }
 
 
 const mapStateToProps = ({ loggedUserState, baseState }) => ({
     isLoggedUser: loggedUserState.isLoggedUser,
     member: loggedUserState.member,
     summits: baseState.summits,
     loading : baseState.loading,
 });
 
 export default connect(
     mapStateToProps,
     {
         getAvailableSummits,
         clearCurrentSummit,
     }
 )(LandingPage);
 
 