/**
 * Copyright 2018 OpenStack Foundation
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 **/

 import {
    getRequest,
    createAction,
    stopLoading,
    startLoading,
    authErrorHandler,
    showMessage
} from "openstack-uicore-foundation/lib/utils/actions";
import { initLogOut} from 'openstack-uicore-foundation/lib/security/methods';
import {getAccessTokenSafely} from "../utils/methods";


export const SELECTION_CLOSED               = 'SELECTION_CLOSED';
export const REQUEST_SUMMIT                 = 'REQUEST_SUMMIT';
export const RECEIVE_SUMMIT                 = 'RECEIVE_SUMMIT';
export const REQUEST_SELECTION_PLAN         = 'REQUEST_SELECTION_PLAN';
export const RECEIVE_SELECTION_PLAN         = 'RECEIVE_SELECTION_PLAN';
export const RECEIVE_MARKETING_SETTINGS     = 'RECEIVE_MARKETING_SETTINGS';
export const RECEIVE_AVAILABLE_SUMMITS      = 'RECEIVE_AVAILABLE_SUMMITS';
export const RECEIVE_ACTION_TYPES           = 'RECEIVE_ACTION_TYPES';
export const ERROR_RECEIVE_SUMMIT           = 'ERROR_RECEIVE_SUMMIT';
export const SET_LOGGED_TRACK_CHAIR         = 'SET_LOGGED_TRACK_CHAIR';
export const CLEAR_SUMMIT                   = 'CLEAR_SUMMIT';
export const CLEAR_SELECTION_PLAN           = 'CLEAR_SELECTION_PLAN';

export const clearCurrentSummit = () => (dispatch) => {
    dispatch(createAction(CLEAR_SUMMIT)({}));
};

export const getAvailableSummits = () => (dispatch) => {

    dispatch(startLoading());

    // depends on user clocks
    const secondsSinceEpoch = Math.round(Date.now() / 1000);

    let filters = [
        `selection_begin_date<=${secondsSinceEpoch}`,
        'selection_plan_enabled==1'
    ];

    let params = {
        'filter[]': filters,
        order: `+start_date`,
        per_page: 20,
        fields: "id,slug,name,start_date,end_date,selection_plans.id,selection_plans.selection_end_date",
        expand: "selection_plans",
        relations: "selection_plans,selection_plans.none"
    };

    return getRequest(
        null,
        createAction(RECEIVE_AVAILABLE_SUMMITS),
        `${window.API_BASE_URL}/api/public/v1/summits/all`,
        authErrorHandler
    )(params)(dispatch).then(() => { dispatch(stopLoading()); });
};

export const getAllFromSummit = (summitSlug) => (dispatch, getState) => {
    dispatch(startLoading());
    return getCurrentSummitPublic(summitSlug)(dispatch, getState)
        .then(({response}) => {
            return getMarketingSettings(response.id)(dispatch).finally(() => {
                dispatch(stopLoading());
            });
        });
};

export const getCurrentSummitPublic = (id) => (dispatch, getState) => {

    let params = {
        expand: 'event_types,event_types.allowed_media_upload_types,event_types.allowed_media_upload_types.type,tracks,selection_plans,locations,locations.rooms,selection_plans.track_groups,selection_plans.track_groups.tracks,'
    };

    return getRequest(
        createAction(REQUEST_SUMMIT),
        createAction(RECEIVE_SUMMIT),
        `${window.API_BASE_URL}/api/public/v1/summits/all/${id}`,
        currentSummitErrorHandler
    )(params)(dispatch);
};

export const clearSelectionPlan = () => (dispatch) => {
    dispatch(createAction(CLEAR_SELECTION_PLAN)({}));
}

export const getSelectionPlan = (summitId, selectionPlanId) => async (dispatch, getState) => {
    const accessToken = await getAccessTokenSafely();

    const params = {
        access_token : accessToken,
        expand: 'summit,track_groups,track_groups.tracks,track_chair_rating_types,track_chair_rating_types.score_types'
    };

    dispatch(startLoading());
    
    return getRequest(
        createAction(REQUEST_SELECTION_PLAN),
        createAction(RECEIVE_SELECTION_PLAN),
        `${window.API_BASE_URL}/api/v1/summits/${summitId}/selection-plans/${selectionPlanId}`,
        selectionPlanErrorHandler
    )(params)(dispatch).then(({response}) => {
        return getActionTypes(response.summit.id, response.id)(dispatch, getState).finally(() => {
            dispatch(stopLoading());
        });
    });
};

export const getMarketingSettings = (summitId) => (dispatch) => {

    let params = {
        per_page: 100,
        page: 1
    };

    return getRequest(
        null,
        createAction(RECEIVE_MARKETING_SETTINGS),
        `${window.MARKETING_API_BASE_URL}/api/public/v1/config-values/all/shows/${summitId}`,
        customErrorHandler
    )(params)(dispatch);
};

export const setLoggedTrackChair = () => (dispatch, getState) => {
    const {loggedUserState, baseState} = getState();
    const { member } = loggedUserState;
    const {summit} = baseState;

    if (member.track_chairs.length > 0) {
        const trackChair = member.track_chairs.find(tc => tc.summit_id === summit.id);
        if (trackChair) {
            dispatch(createAction(SET_LOGGED_TRACK_CHAIR)({trackChair}));
            return true;
        }
    }

    return false;
};

export const getActionTypes = (summitId, selectionPlanId) => async (dispatch) => {
    const accessToken = await getAccessTokenSafely();

    const params = {
        per_page: 100,
        page: 1,
        order: '+order',
        access_token: accessToken,
    };

    return getRequest(
        null,
        createAction(RECEIVE_ACTION_TYPES),
        `${window.API_BASE_URL}/api/v1/summits/${summitId}/selection-plans/${selectionPlanId}/allowed-presentation-action-types`,
        customErrorHandler
    )(params)(dispatch);
};

const notAllowedMessage = () => (dispatch) => {
    const error_message = {
        title: 'Restricted Access',
        html: 'Your user does not have permission to access this application. You may need to logout and log back in if you were just granted permission.',
        type: 'error'
    };

    dispatch(showMessage( error_message, initLogOut ));
}

const customErrorHandler = (err, res) => (dispatch) => {
    const code = err.status;

    dispatch(stopLoading());

    if (code === 403) {
        dispatch(notAllowedMessage());
    } else {
        dispatch(authErrorHandler(err, res));
    }
};

const currentSummitErrorHandler = (err, res) => (dispatch) => {
    const code = err.status;

    dispatch(stopLoading());

    if (code === 404) {
        dispatch(createAction(ERROR_RECEIVE_SUMMIT)({}));
    } else {
        dispatch(authErrorHandler(err, res));
    }
};

export const selectionPlanErrorHandler = (err, res) => (dispatch) => {
    const code = err.status;
    dispatch(stopLoading());

    if (code === 404) {
        dispatch(createAction(SELECTION_CLOSED)({}));
    } else {
        dispatch(authErrorHandler(err, res));
    }
};

